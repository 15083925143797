import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`I säsongens sista avsnitt ser vi hur både OpenStreetMap och Pine64 växer så det knakar, med alla problem och möjligheter det innebär. I Kort & Gott snackar vi om GamingOnLinux, nya Proton Drive, Raymond Knops, Lutris och mer. Vi rundar av med en trevlighet och diskussion om bland annat vår kommande Nyårsspecial.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Pine Store Community: `}<a parentName="li" {...{
          "href": "https://www.pine64.org/2020/12/02/pine-store-community-pricing-online-retail-stores/"
        }}>{`https://www.pine64.org/2020/12/02/pine-store-community-pricing-online-retail-stores/`}</a></li>
      <li parentName="ul">{`OpenStreetMap: `}<a parentName="li" {...{
          "href": "https://joemorrison.medium.com/openstreetmap-is-having-a-moment-dcc7eef1bb01"
        }}>{`https://joemorrison.medium.com/openstreetmap-is-having-a-moment-dcc7eef1bb01`}</a></li>
    </ul>
    <h3>{`Kort & Gott`}</h3>
    <ul>
      <li parentName="ul">{`GamingOnLinux månadsuppdatering: `}<a parentName="li" {...{
          "href": "https://www.gamingonlinux.com/2020/12/the-monthly-roundup-november-2020"
        }}>{`https://www.gamingonlinux.com/2020/12/the-monthly-roundup-november-2020`}</a></li>
      <li parentName="ul">{`Proton Drive: `}<a parentName="li" {...{
          "href": "https://protonmail.com/blog/proton-drive-early-access/"
        }}>{`https://protonmail.com/blog/proton-drive-early-access/`}</a></li>
      <li parentName="ul">{`Raymond Knops sprattlar till liv igen: `}<a parentName="li" {...{
          "href": "https://twitter.com/EdoPlantinga/status/1069647075279810561"
        }}>{`https://twitter.com/EdoPlantinga/status/1069647075279810561`}</a></li>
      <li parentName="ul">{`Lutris 0.5.8: `}<a parentName="li" {...{
          "href": "https://www.gamingonlinux.com/2020/11/open-source-game-manager-lutris-sees-the-big-058-release-out-now"
        }}>{`https://www.gamingonlinux.com/2020/11/open-source-game-manager-lutris-sees-the-big-058-release-out-now`}</a></li>
      <li parentName="ul">{`Sabayon + Funtoo = Mocaccino (tack Christer): `}<a parentName="li" {...{
          "href": "https://www.sabayon.org/article/sabayon-is-rebranding/"
        }}>{`https://www.sabayon.org/article/sabayon-is-rebranding/`}</a>{` `}<a parentName="li" {...{
          "href": "https://www.mocaccino.org/"
        }}>{`https://www.mocaccino.org/`}</a></li>
      <li parentName="ul">{`Github Universe: `}<a parentName="li" {...{
          "href": "https://githubuniverse.com/"
        }}>{`https://githubuniverse.com/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`ncdu: `}<a parentName="li" {...{
          "href": "https://dev.yorhel.nl/ncdu"
        }}>{`https://dev.yorhel.nl/ncdu`}</a></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Emacs?! (Magnus på mastodon)`}</li>
      <li parentName="ul">{`Tack för donationerna`}</li>
      <li parentName="ul">{`Recensionerna har stagnerat`}</li>
      <li parentName="ul">{`Säsongsbreak `}</li>
      <li parentName="ul">{`Nyårsspecial 2020`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://social.gelotte.dev/@seb"
        }}>{`@seb`}</a></li>
      <li parentName="ul">{`Seb på twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
      <li parentName="ul">{`Donationer: `}<a parentName="li" {...{
          "href": "https://liberapay.com/TrevligMjukvara/donate"
        }}>{`https://liberapay.com/TrevligMjukvara/donate`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Backbay Lounge" by Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      